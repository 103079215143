import React, { useState, useEffect } from 'react'
import { Modal, OutlinedInput,InputAdornment, Fade, makeStyles, Box, Link, Select, MenuItem, FormControl, InputLabel, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'
import { connect } from 'react-redux'
import CloseIcon from '@material-ui/icons/Close'
import { actCloseAddFunds, actCreateCharge, actResetNewChargeCode } from '../redux/backend/add-funds'
import { useAuth0 } from "../redux/auth"
import { useDialogStyles } from './dialog'
import { openTermsAndConditions } from '../util/util'


const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.background.paper,

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    closeIcon: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
    },
    content: {
        width: 340,
        paddingBottom: theme.spacing(12),
    },
    title: {
        fontSize: 20,
        fontWeight: 600,
        color: '#121212',
        display: 'block',
        marginBottom: theme.spacing(1),
    },
    disclaimer: {
        color: '#6C7380',
        fontSize: 12,
        display: 'block',
    },
    controlBlock: {
        marginTop: theme.spacing(3),
        display: 'flex',
    },
    control: {
    },
    controlSpacer: {
        flexGrow: 1,
    },
    addFundsButton: {
        width: '100%',
        marginTop: theme.spacing(5),
    },
    select: {
        fontWeight: 700,
    },
}))

const AddFundsModal = (props) => {
    const classes = useStyles()

    const close = () => {
        props.dispatch(actCloseAddFunds())
        setCurrencyType('BTC')
        setAmtC(500)
    }

    const closeError = () => {
        props.dispatch(actResetNewChargeCode())
        props.dispatch(actCloseAddFunds())
        setCurrencyType('BTC')
        setAmtC(500)
    }

    const [amtC, setAmtC] = useState(500)
    const [currencyType, setCurrencyType] = useState('BTC')
    const minAmountBtc = 2
    const minAmountUsdt = 10

    const [buttonState, setButtonState] = useState(false)

    const changeAmtC = (ev) => {
        if (isNaN(ev.target.value) || ev.target.value == "" || ev.target.value == null || ev.target.value == undefined){
            setButtonState(true)
            setAmtC(0)
        }  else {
            if (currencyType == 'trc20usdt') {
                if (ev.target.value < minAmountUsdt) {
                    setButtonState(true)
                    setAmtC(ev.target.value*100)
                } else {
                    setButtonState(false)
                    setAmtC(ev.target.value*100)
                }
            } else {
                if (ev.target.value < minAmountBtc) {
                    setButtonState(true)
                    setAmtC(ev.target.value*100)
                } else {
                    setButtonState(false)
                    setAmtC(ev.target.value*100)
                }
            }
            
      }
    }

    const changeCurrencyType = (ev) => {
        if (ev.target.value == 'trc20usdt') {
            if(amtC/100 < minAmountUsdt ){
                setAmtC(minAmountUsdt*100)
                setButtonState(false)
            }  
            // console.log('AMOUNT :' +amtC)
          } else {
            if(amtC/100 < minAmountBtc ){
                setAmtC(minAmountBtc*100)
                setButtonState(false)
            }
            
          }
        setCurrencyType(ev.target.value)
     
    }

    const { getAccessTokenSilently } = useAuth0()
    const addFunds = () => {
        close()
        props.dispatch(actCreateCharge(getAccessTokenSilently,
                                        amtC/100,
                                        props.account.userID,
                                        props.account.email,
                                        currencyType))
    }

    const dispatch = props.dispatch
    const newChargeCode = props.newChargeCode
    const now = new Date()

    const invoiceItem = {
      newChargeCode: props.newChargeCode,
      currencyType: props.currencyType,
      currencyAddress: props.currencyAddress,
      currencyAmount: props.currencyAmount,
      amountUSD: props.amountUSD,
      expire: now.getTime()/1000 + 60*60*6,
    }

    useEffect(() => {
        if (newChargeCode) {
            dispatch(actResetNewChargeCode())
            sessionStorage.clear()
            const url = window.location.origin+'/charges/' + newChargeCode
            sessionStorage.setItem(newChargeCode, JSON.stringify(invoiceItem))

            window.open(url,"_blank")
        }
    }, [newChargeCode, dispatch])

    const commonSelectProps = {
        className: classes.select,
    }
    const commonFormControlProps = {
        className: classes.control,
        variant: 'outlined',
    }

    const dialogClasses = useDialogStyles()

    return (
        <>
            <Modal open={props.canDeposit && props.active} onClose={close}>
                <Fade in={props.canDeposit && props.active}>
                    <Box className={classes.paper}>
                        <CloseIcon className={classes.closeIcon} onClick={close} />

                        <Box className={classes.content}>
                    <span className={classes.title}>Add funds to balance</span>
                    <span className={classes.disclaimer}>
                        Once you click 'Add Funds' below, a new tab will be opened with our payment provider
                        showing the cryptocurrency wallet ID and a QR code for you to use. <br /><br />
                        <strong>Minimum amount is {currencyType == 'trc20usdt'? '10$': '5$'}</strong><br /><br />
                        {currencyType == 'trc20usdt' && 
                            <strong>
                                If you will send less than 10$ , transfer commission will be on deducted from your sum and it can be near 3$.
                            </strong>
                        }
                        <strong>Please allow pop-ups if your browser asks you.</strong><br /><br />
                        After the crypto transaction is completed, the funds will
                        appear on your balance within few minutes. <br /><br />
                        Please note that balance cannot be refunded to currency. <br />
                        See our <Link href="#" onClick={openTermsAndConditions}>Terms and Conditions</Link> for more details.
                    </span>
                    <Box className={classes.controlBlock}>
                        <FormControl {...commonFormControlProps}>
                            <InputLabel>Currency</InputLabel>
                            <Select style={{width: 180}} label='Currency' {...commonSelectProps} onChange={changeCurrencyType} defaultValue={currencyType}>
                                <MenuItem value='BTC'>BTC</MenuItem>
                                <MenuItem value='LTC'>LTC</MenuItem>
                                <MenuItem value='trc20usdt'>USDT TRC20</MenuItem>
                            </Select>
                        </FormControl>
                        <Box className={classes.controlSpacer} />
                        <FormControl {...commonFormControlProps}>
                        <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                            <OutlinedInput
                              required
                              id="outlined-adornment-amount"
                              onChange={changeAmtC}
                              style={{width: 140}}
                              // {...commonSelectProps}
                              startAdornment={<InputAdornment position="start">$</InputAdornment>}
                              label="Amount"
                              value={amtC/100}
                              />
                        </FormControl>
                        </Box>
                            <Button
                                disabled={buttonState}
                                className={classes.addFundsButton}
                                variant='contained'
                                color='primary'
                                disableElevation
                                onClick={addFunds}
                                >
                                    Add Funds
                            </Button>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
            <Dialog open={!props.canDeposit && props.active} classes={{paper: dialogClasses.dialog}} onClose={close}>
                <DialogTitle>Your account is under review</DialogTitle>
                <DialogContent className={dialogClasses.content}>
                    You can't add funds until your account is reviewed. <br />
                    Please allow 24 hours for the approval of a new account. <br />
                </DialogContent>
                <DialogActions>
                    <Button onClick={close} color='primary'>Dismiss</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={props.errorPayment} classes={{paper: dialogClasses.dialog}} onClose={closeError}>
                <DialogTitle>Payment Error</DialogTitle>
                <DialogContent className={dialogClasses.content}>
                   Please try again later. <br />
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeError} color='primary'>Dismiss</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const mapStateToProps = (state) => ({
    account: state.account,
    active: state.addFunds.modelOpen,
    newChargeCode: state.addFunds.newChargeCode,
    canDeposit: state.account?.permissions.includes('DEPOSIT'),
    currencyType: state.addFunds.currencyType,
    currencyAddress: state.addFunds.currencyAddress,
    currencyAmount: state.addFunds.currencyAmount,
    currencyUSD: state.addFunds.amountUSD,
    errorPayment: state.addFunds.error,
})

export default connect(mapStateToProps)(AddFundsModal)
