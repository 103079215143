import React from 'react';
import TopBar, { topBarHeight } from './TopBar';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { Box, makeStyles } from '@material-ui/core';
import Shop from './Shop';
import MyProxyPage from './my-proxies/MyProxyPage';
import { connect, useDispatch } from 'react-redux';
import LeaseFlow from './leaseflow/LeaseFlow';
import GlobalBusyIndicator from './GlobalBusyIndicator';
import PaymentPage from './payments/PaymentPage';
import AddFundsModal from './AddFundsModal';
import AdminPage from './admin/AdminPage';
import RenewFlow from './renew/RenewFlow';
import LegalModal from './legal/LegalModal';
import { IPScoreSnackBar } from './IPScoreSection';
import FaqPage from './FaqPage';
import DownloadPage from './DownloadPage';
import ApiPage from './ApiPage';
import ChargePage from './ChargePage';
import Ports from './ports/Ports';
import NewPortModal from './ports/NewPortModal';
import StaticProxyShop from './staticproxy/StaticProxyShop'
import PortBuyConfirmDialog from './ports/PortBuyConfirmDialog';
import '../App.css';
// import MobileDrawerMenu from './mobileMenu/mobileDrawerMenu';
import ChangePasswordMobile from './changePasswordMobile/changePasswordMobile';
import MyFundsMobile from './myFunds/myFundsMobile';
import Filter from './shopMobile/filter';
import ConfirmPurchase from './confirmPurchase/confirmPurchase';
import ShopId from './ShopId/shopId';
import MyProxieDetails from './myProxiesMobile/proxyDetails';
import HistoryDetails from './ProxyHistoryMobile/historyDetails';
import { useEffect } from 'react';
import { getActiveMenuItem } from '../redux/backend/activeMenuItem'
import ReferralPage from './ReferralPage'

const useStyles = makeStyles((theme) => ({
    app: {
        alignItems: 'stretch',
    },
    content: {
        paddingTop: topBarHeight,
        position: 'absolute',
        top: 0,
        height: '100vh',
        maxHeight: '100vh',
        width: '100%',
    },
}));

const App = (props) => {

    return (<>
        <BrowserRouter>
            <GlobalBusyIndicator />
            <TopBar />
            <Switch>
                {
                    window.innerWidth <= 480 &&
                    <Route path="/shop/:id">
                        <ShopId />
                    </Route>
                }
                {
                    window.innerWidth <= 480 &&
                    <Route path="/my/history-details/:id">
                        <HistoryDetails />
                    </Route>
                }
                <Route path="/shop">
                    <Shop />
                </Route>

                {/* <Route path="/ports">
                    <Ports />
                </Route> */}
                {/* <Route path="/static">
                    <StaticProxyShop />
                </Route> */}
                {
                    window.innerWidth <= 480 &&
                    <Route path="/my/:id">
                        <MyProxieDetails />
                    </Route>
                }
                <Route path="/my">
                    <MyProxyPage />
                </Route>
                <Route path="/payments">
                    <PaymentPage />
                </Route>
                <Route path="/faq">
                    <FaqPage />
                </Route>
                <Route path="/download">
                    <DownloadPage />
                </Route>
                <Route path="/api">
                    <ApiPage />
                </Route>
                <Route path="/faq">
                    <FaqPage />
                </Route>
                <Route path="/charges/:transactionId">
                    <ChargePage />
                </Route>
                <Route path="/bigadmin">
                    <AdminPage />
                </Route>
                <Route path="/change-password">
                    <ChangePasswordMobile />
                </Route>
                <Route path="/referral">
                    <ReferralPage />
                </Route>
                {
                    window.innerWidth <= 480 &&
                    <Route path="/add-funds">
                            <MyFundsMobile />
                    </Route>
                }
                {
                    window.innerWidth <= 480 &&
                    <Route path="/filter">
                        <Filter />
                    </Route>
                }
                {
                    window.innerWidth <= 480 &&
                    <Route path="/confirm-purchase">
                        <ConfirmPurchase />
                    </Route>
                }
                <Route path="/" element={window.innerWidth <= 480
                    ? <Redirect to="/shop" /> : <Shop />}>
                    <Shop />
                </Route>
            </Switch>
            {/* </Box>
                </Box> */}
            <LeaseFlow />
            <AddFundsModal />
            <RenewFlow />
            <LegalModal />
            <IPScoreSnackBar />
            {/* <NewPortModal /> */}
            <PortBuyConfirmDialog />
        </BrowserRouter>
    </>
    );
};

const mapStateToProps = state => ({
    account: state.account,
    active: state.activeItem
});

export default connect(mapStateToProps)(App);
