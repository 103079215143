import React from 'react';
import { connect } from 'react-redux';
import { makeStyles, Box, Button, Tooltip } from '@material-ui/core';
import { topBorder } from '../styles/common';
import { countryNames } from '../data/countryNames';
import ReactCountryFlag from 'react-country-flag';
import { actSetLeaseType, humanizedActivity, humanizedAgeSinceFirstSeen } from '../redux/backend/proxies';
import { formatMoney } from '../util/money';
import { actStartLease } from '../redux/backend/lease';
import ProxyDetails from './ProxyDetails';
import InfoIcon from '@material-ui/icons/Info';


const inactiveLeaseTypeColor = '#A3A8B3';

export const sidebarContentStyle = (theme) => ({
    fontSize: 15,

    display: 'grid',
    gridTemplateRows: 'auto 1fr auto auto',
    gridTemplateColumns: '1fr',
    maxHeight: '100%',
    minHeight: '100%',
    minWidth: 100,
    [theme.breakpoints.down('sm')]: {
        width: 264,
        maxWidth: 264,
    },
    [theme.breakpoints.up('md')]: {
        width: 320,
        maxWidth: 320,
    },
    [theme.breakpoints.up('xl')]: {
        width: 400,
        maxWidth: 400,
    },
});

const useStyles = makeStyles((theme) => ({
    sidebarContent: sidebarContentStyle(theme),
    sidebarInfo: {
        padding: theme.spacing(3),
        paddingBottom: 0,
        gridRow: '1 / 2',
        maxHeight: '100%',
        overflowY: 'scroll',
    },
    section: {
        marginTop: theme.spacing(2),
    },
    sidebarDetails: {
        padding: 0,
        marginTop: theme.spacing(2),
        gridRow: '2 / 3',
        maxHeight: '100%',
        overflowY: 'scroll',
    },
    sidebarLeaseChoice: {
        flexGrow: 1,
        padding: theme.spacing(3),
        gridRow: '3 / 4',

        display: 'grid',
        gridTemplateRows: 'auto',
        gridTemplateColumns: '1fr 1fr',
        columnGap: theme.spacing(1),

        width: '100%',
    },
    sidebarButtonContainer: {
        ...topBorder(theme),
        padding: theme.spacing(3),
        gridRow: '4 / 5',
    },
    sidebarButton: {
        width: '100%',
    },
    host: {
        marginBottom: theme.spacing(2),
        fontWeight: 600,
        overflowWrap: 'break-word',
    },
    ip: {
        marginBottom: theme.spacing(2),
    },
    flag: {
        marginLeft: theme.spacing(1),
        position: 'relative',
        bottom: 1,
    },
    leaseTypeSelector: window.innerWidth > 480 ? {
        cursor: 'pointer',

        padding: theme.spacing(2),
        marginBottom: theme.spacing(1),

        borderWidth: 2,
        borderColor: inactiveLeaseTypeColor,
        borderStyle: 'solid',
        borderRadius: 4,

        color: inactiveLeaseTypeColor,

        display: 'flex',
        flexDirection: 'row',
    } : {
        width: '90%',
        margin: 'auto',
        cursor: 'pointer',
        padding: theme.spacing(2),
        marginBottom: theme.spacing(1),
        borderWidth: 2,
        borderColor: inactiveLeaseTypeColor,
        borderStyle: 'solid',
        borderRadius: 4,
        color: inactiveLeaseTypeColor,
        display: 'flex',
        flexDirection: 'row',
    },
    leaseTypeSelectorSelected: {
        backgroundColor: 'rgba(76,151,255,0.05)',
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        fontWeight: 600,
    },
    leaseTypeSelectorType: {
        flexGrow: 1,
    },
    leaseTypeSelectorPrice: {
        fontWeight: 600,
    },
    activityOk: {

    },
    activityMedium: {
        color: '#b47b05',
        fontWeight: 700,
    },
    activityLow: {
        color: '#E65639',
        fontWeight: 700,
    },
    infoChangeIcon: {
        height: 18,
        position: 'relative',
        top: 4,
        left: -4,
        fill: theme.palette.grey[500],
    },
}));

export const LeaseTypeSelector = (props) => {
    const classes = useStyles();

    const handleClick = () => {
        props.dispatch(actSetLeaseType(props.leaseType));
    };

    return (
        <Box
            className={`${classes.leaseTypeSelector} ${props.value === props.leaseType ? classes.leaseTypeSelectorSelected : ''}`}
            onClick={handleClick}
            id={'lease_type_selector_' + props.leaseType}
        >
            <Box className={classes.leaseTypeSelectorType}>{props.name}</Box>
            <Box className={classes.leaseTypeSelectorPrice}>{formatMoney(props.price)}</Box>
        </Box>
    );
};

const leaseTypeLabels = {
    'SHARED': 'Shared',
    'PRIVATE': 'Private',
};

const ProxySidebar = (props) => {
    const classes = useStyles();

    const handleBuyClick = () => {
        if (props.onBuy) {
            props.onBuy();
        }
        props.dispatch(actStartLease(props.selected));
        // console.log(props.selected, 'props.selected');
    };

    const activityClass = (actv, probeActv) => {
        let a = actv;
        if (probeActv && probeActv > 0) {
            a = probeActv;
        }

        if (a >= 0.7) {
            return classes.activityOk;
        } else if (a >= 0.4) {
            return classes.activityMedium;
        } else {
            return classes.activityLow;
        }
    };

    if (props.selected) {
        const host = props.selected.host;
        const ip = props.selected.ip;

        return (
            <Box className={classes.sidebarContent} id='sidebar'>
                <Box className={classes.sidebarInfo}>
                    {host &&
                        <Box className={classes.host}>{host}</Box>}
                    <Box className={host ? classes.ip : classes.host}>
                        {ip}
                    </Box>
                    <Box variant='body1'>
                        {countryNames[props.selected.loc?.cc]}
                        <ReactCountryFlag className={classes.flag} countryCode={props.selected.loc?.cc} svg />
                    </Box>
                    <Box variant='body1'>{props.selected.loc?.reg}</Box>
                    <Box variant='body1'>{props.selected.loc?.city}</Box>
                    <Box className={classes.section}>
                        <Box>
                            <Tooltip title="time since the proxy was added">
                                <InfoIcon className={classes.infoChangeIcon} />
                            </Tooltip>
                            <span>Uptime: {humanizedAgeSinceFirstSeen(props.selected.firstSeen)}</span>
                        </Box>
                        <Box variant='body1'>
                            <Tooltip title="% of time the proxy is online">
                                <InfoIcon className={classes.infoChangeIcon} />
                            </Tooltip>
                            <span>
                                Activity:&nbsp;
                                <span className={activityClass(props.selected.actv, props.selected.probeActv)}>
                                    {humanizedActivity(props.selected.actv, props.selected.probeActv)}
                                </span>
                            </span>
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.sidebarDetails}>
                    <ProxyDetails
                        proxy={props.selected}
                        proxyId={props.selected.id}
                        scoreCheck={props.selected.scoreCheck}
                        speedCheck={props.selected.speed}
                    />
                </Box>
                <Box className={classes.sidebarLeaseChoice}>
                    {props.selected.priceExcC && <LeaseTypeSelector
                        name={leaseTypeLabels['PRIVATE']}
                        value={props.leaseType}
                        dispatch={props.dispatch}
                        price={props.selected.priceExcC}
                        leaseType='PRIVATE' />}
                   {props.selected.priceShrC && <LeaseTypeSelector
                        name={leaseTypeLabels['SHARED']}
                        value={props.leaseType}
                        dispatch={props.dispatch}
                        price={props.selected.priceShrC}
                        leaseType='SHARED' />}
                </Box>
                <Box className={classes.sidebarButtonContainer}>
                    <Button
                        id='lease_button'
                        className={classes.sidebarButton}
                        variant='contained'
                        disableElevation
                        color='primary'
                        onClick={handleBuyClick}
                    >
                        Buy {leaseTypeLabels[props.leaseType]} Proxy
                    </Button>
                </Box>
            </Box>
        );
    } else {
        return <Box className={classes.sidebarContent} />;
    }
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps)(ProxySidebar);
